//colors
$theme_color1:#0b1a2f;
$theme_color2:#0b1526;
$theme_color3:#091220;

$font_color:#8291ad;
$white:#ffffff;
$accent_color:#d2d600;
$black:#000000;

//fonts
$main_font:'Poppins', sans-serif;

//mixins
@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin glowing_shadow {
    -webkit-box-shadow: 0px 0px 43px 0px rgba(211,216,0,0.5);
    -moz-box-shadow: 0px 0px 43px 0px rgba(211,216,0,0.5);
    box-shadow: 0px 0px 43px 0px rgba(211,216,0,0.5);
}

@mixin top_gradient{
    background: rgba(10,26,48,0);
    background: -moz-linear-gradient(top, rgba(10,26,48,0) 0%, rgba(11,26,47,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(10,26,48,0)), color-stop(100%, rgba(11,26,47,1)));
    background: -webkit-linear-gradient(top, rgba(10,26,48,0) 0%, rgba(11,26,47,1) 100%);
    background: -o-linear-gradient(top, rgba(10,26,48,0) 0%, rgba(11,26,47,1) 100%);
    background: -ms-linear-gradient(top, rgba(10,26,48,0) 0%, rgba(11,26,47,1) 100%);
    background: linear-gradient(to bottom, rgba(10,26,48,0) 0%, rgba(11,26,47,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1a30', endColorstr='#0b1a2f', GradientType=0 );
}

// ANIMATIONS
$color-main: $accent_color;
$black: $theme_color1;

.has-animation {
  position: relative;
  h1, p, img {
      opacity: 0;
  }
  &.animate-in {
    p, img {
      animation: textHidden 0.1s 1.1s forwards;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 10;
    }
    &:before {
      background-color: $color-main;
    }
    &:after {
      background-color: $black;
      animation-delay: .5s;
    }
  }
  &.animation-ltr.animate-in {
    &:before {
      animation: revealLTR 1.8s ease;
    }
    &:after {
      animation: revealLTR 1s .6s ease;
    }
  }
  &.animation-rtl.animate-in {
    &:before {
      animation: revealRTL 1.8s ease;
    }
    &:after {
      animation: revealRTL 1s .6s ease;
    }
  }
}

@keyframes revealRTL {
  0%{
    width: 0;
    right: 0;
  }
  65%{
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}

@keyframes revealLTR {
  0%{
    width: 0;
    left: 0;
  }
  65%{
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes textHidden {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menuhover{
    0% {left:100%}
    90%{width: 8px;}
    100% {left: 0;width: 8px;}
  }

//default styles
.default{
    background-color: $theme_color1;
    color: $font_color;
    h1,h2,h3,h4,h5,h6{
        color: $white;
    }
}

.more_btn{
    padding: 18px 62px 18px 37px;
    border: 2px solid $accent_color;
    border-radius: 50px;
    color: $white;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 1.5px;
    @include anim;
    white-space: nowrap;
    @media only screen and (max-width: 1500px) {
       font-size: 13px;
       line-height: 13px;
       padding: 18px 62px 18px 37px;
    }

    @media only screen and (max-width: 991px) {
        letter-spacing: 0;
        white-space: nowrap;
    }
    &.normal{
        border: 2px solid $font_color;
        &:hover{
            border:2px solid $accent_color;
        }
    }
    &:hover{
        color: $white;
        text-decoration: none;
        &:after{
            margin-left: -25px;
        }
    }
    &:before{
        content: '';
        position: absolute;
        left: 100%;
        margin-left: -40px;
        top: 48%;
        width: 4px;
        height: 0;
        padding-bottom: 4px;
        background-color: $accent_color;
    }
    &:after{
        content: '';
        position: absolute;
        left: 100%;
        margin-left: -40px;
        top: 48%;
        width: 50px;
        height: 0;
        padding-bottom: 4px;
        background-color: $font_color;
        @include anim;
    }
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.default_font{
    font-size: 15px;
    line-height: 30px;
    color: $font_color;
    @media only screen and (max-width: 1520px) {
        font-size: 13px;
        line-height: 27px;
    }
    h1{
        font-size: 72px;
        line-height: 82px;
        @media only screen and (max-width: 1670px) {
            font-size: 52px;
            line-height: 72px;
        }
        @media only screen and (max-width: 1500px) {
            font-size: 42px;
            line-height: 62px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 37px;
            line-height: 57px;
        }
    }
    h2{
        font-size: 42px;
        line-height: 62px;
    }
    h3{
        font-size: 25px;
        line-height: 45px;
        @media only screen and (max-width: 1500px) {
            font-size: 23px;
            line-height: 43px;
        }
    }
    h4{
        font-size: 25px;
        line-height: 37px;
        @media only screen and (max-width: 1500px) {
            font-size: 20px;
            line-height: 32px;
        }
    }
    h5{
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 15px;
            line-height: 25px;
        }
    }
    ul{
        margin-left: -13px;
        li{
            padding-left: 54px;
            background-repeat: no-repeat;
            background-position: left top;
            background-image: url('./../img/check_icon.png');
            padding-top: 9px;
            min-height: 40px;
        }
    }
    ol{
        padding-left: 18px;
        color: $accent_color;
        p{
            color: $font_color;
        }
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        color: $white;
    }
    strong{
        color: $white;
    }
    a{
        text-decoration: underline;
        color: $accent_color;
    }
}

body{
    background: $theme_color1;
    font-family: $main_font;
    margin: 0;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    &.scroll_block{
        overflow: hidden;
        > .main_page{
            opacity: 0.4;
        }
    }
}

.main_page{
    @include anim;
}

//section headers

.section_header1{
    padding-left: 63px;
    position: relative;
    //text-align: center;
    color: $white;
    font-size: 18px;
    line-height: 32px;
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        text-align: center;
        padding-top: 30px;
        margin-bottom: 50px;
    }
    p{
        padding-top: 10px;
    }
    &:before{
       content: '';
       width: 7px;
       height: 0;
       padding-bottom: 7px;
       background: $accent_color;
       position: absolute;
       top: 46%;
       left: 0;
       @media only screen and (max-width: 991px) {
            left: -40px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -2px;
        }
    }
    &:after{
        content: '';
        height: 3px;
        width: 46px;
        background: #555f6e;
        position: absolute;
        top: 50%;
        left: 18px;
        @media only screen and (max-width: 991px) {
            left: 32px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 0;
        }
    }
    .default_font{
        padding-left: 24px;
        color: $font_color;
        font-weight: 200;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        h1,h2,h3,h4,h5,h6{
            font-weight: inherit;
            color: inherit;
        }
        strong{
            color: $white;
        }
    }
}

.section_header2{
    margin-bottom: 30px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    div.default_font{
        color: $white;
    }
}

div.popup1{
    height: 100vh;
    position: fixed;
    z-index: 10;
    width: 100%;
    left: -100%;
    top: 0;
    div.popup_content{
        background: $theme_color2;
        padding: 30px;
        border:1px solid $accent_color;
        div.desc{
            height: 200px;
            padding-right: 30px;
        }
    }
    @media only screen and (max-width: 991px) {
        overflow-y: scroll;
    }
    
    header{
        position: relative;
        padding-right: 50px;
        margin-bottom: 30px;
        div.default_font{
            h2{
                @media only screen and (max-width: 991px) {
                    font-size: 23px;
                }
            }
        }
        div.close_popup{
            position: absolute;
            top: 0;
            right: -14px;
            cursor: pointer;
            z-index: 5;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('./../img/close123.png');
            width: 50px;
            height: 100%;
            position: absolute;
        }
        @media only screen and (max-width: 991px) {
            margin-top: 14px;
        }
        span{
            color: $accent_color;
        }
        color: $white;
    }
}

div.main_page{
    > div{
        &.page{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 440px);
            position: relative;
            @media only screen and (max-width: 1500px) {
                width: calc(100% - 340px);
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            
        }
    }

    &.form_off > .page
    {
        width: 100%!important;
    }
    .select_multi_box {
        .multi_list {
            color:#d2d600;
            font-size:16px;
            li {
                position:relative;
                padding-top:10px;

                span {
                    position:absolute;
                    right:0;
                    top:15px;
                }
            }
        }

        .chosen-drop {
            border:1px solid #8291ad!important;
            border-top:0!important;
            input {
                border:1px solid #d2d600;
                color:#d2d600;
            }
        }
    }

    > aside{
        overflow-y: scroll;
        margin-right: -30px;
        @media only screen and (max-width: 991px) {
            margin-right: 0;
        }
        > div{
            padding-right: 30px;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
            }
        }
        &.side_form{
            padding-top: 3.5vw;
            padding-left:38px;
            padding-right:38px;
            background: $theme_color2;
            width: 470px;
            height: 100%;
            position: fixed;
            top: 0;
            right: 0;

            &.form_off {
                display:none;
            }

            @media only screen and (max-width: 1500px) {
                width: 370px;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                position: relative;
                margin-right: 0;
                padding-left: 15px;
                padding-right: 15px;
            }
            .section_header1{
                &:before{
                    top: 42%;
                }
                h2{
                    font-size: 20px;
                    padding-left: 30px;
                }
            }
            form{
                margin-top: 50px;
                @media only screen and (max-width: 1500px) {
                    margin-top: 25px;
                }
                .inline{
                    > div{
                        display: inline-block;
                        vertical-align: top;
                        width: 48%;
                        margin-right: 4%;
                        &:nth-child(2n),&:last-child{
                            margin-right: 0;
                        }
                    }
                }
                div.holder{
                    margin-bottom: 20px;
                    input{
                        width: 100%;
                        outline: none;
                        background: transparent;
                        &.input{
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 2px solid #8291ad;
                            caret-color: $accent_color;
                            color: $accent_color;
                            font-size: 16px;
                            height: 50px;
                            font-weight: 700;
                            @media only screen and (max-width: 1500px) {
                                font-size: 13px;
                                line-height: 13px;
                                height: 40px;;
                            }
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus {
                                border-bottom: 2px solid #8291ad;
                                -webkit-text-fill-color: #d2d600;
                                -webkit-box-shadow: 0 0 0px 1000px #0b1526 inset;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }
                        &.submit{
                            margin-top: 20px;
                            padding: 0 30px;
                            height: 55px;
                            width: 100%;
                            border: 2px solid$accent_color;
                            border-radius: 50px;
                            color: #ffffff;
                            font-weight: 700;
                            text-decoration: none;
                            position: relative;
                            display: inline-block;
                            font-size: 15px;
                            line-height: 15px;
                            letter-spacing: 1.5px;
                            @include anim;
                            margin-bottom: 50px;
                            &:hover{
                                background: $accent_color;
                                color: $theme_color1;
                            }
                        }
                    }
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $font_color;
                    }
                    ::-moz-placeholder { /* Firefox 19+ */
                    color: $font_color;
                    }
                    :-ms-input-placeholder { /* IE 10+ */
                    color: $font_color;
                    }
                    :-moz-placeholder { /* Firefox 18- */
                    color: $font_color;
                    }
                    .chosen-container{
                        &.chosen-container-single{
                            .chosen-default{
                                color: $font_color;
                            }
                        }
                        .chosen-drop{
                            background: $theme_color2;
                            border: none;
                        }
                        .chosen-results{
                            color: $font_color;
                            li{
                                text-align: left;
                                &.highlighted{
                                    background: $accent_color;
                                }
                                &.no-results{
                                    background: $accent_color;
                                    color: $white;
                                }
                            }
                        }
                        .chosen-single{
                            height: 45px;
                            padding-left: 0px;
                            background: transparent;
                            box-shadow: none;
                            border: none;
                            border-bottom:2px solid #8291ad;
                            border-radius: 0;
                            font-size:16px;

                            span{
                                text-align: left;
                                padding-top: 10px;
                                color: $font_color;
                            }
                            div{
                                width: 50px;
                                b{
                                    background-position: center;
                                    background-image: url('./../img/select_arr.png');
                                }
                            }
                        }
                    }
                }
                .rules{
                    > div{
                         display: inline-block;
                         vertical-align: top;

                         &.rules_desc{
                             color: $white;
                             font-size: 13px;
                             line-height: 13px;
                             padding: 7px 0 0 15px;
                             width: calc(100% - 25px);
                             @media only screen and (max-width: 1500px) {
                                font-size: 10px;
                                line-height: 10px;
                                padding: 9px 0 0 10px;
                             }
                             p{
                                 font-weight: inherit !important;
                                 font-size: inherit;
                                 line-height: inherit;
                             }
                             span{
                                 color: $accent_color;
                                 cursor: pointer;
                             }
                         }
                     }
                 }
            }
        }
    }
}

.box{
    width: 25px;
    height: 0;
    padding-bottom: 21px;
    position: relative;
    cursor: pointer;
    border: 2px solid #8291ad;
    .checkbox_image_box {
        > div {
            @include fill;
            display: none;
            background-repeat: no-repeat;
            background-position: center;
            display: none;

            &.yes {
                &.on {
                    display: block;
                    background-image: url('/img/check123.png');
                }
            }
        }
    }
}


.chosen-container-single {
    .chosen-single {
        @media only screen and (max-width: 1500px) {
            height: 40px !important;
        }
        span{
            @media only screen and (max-width: 1500px) {
                padding-top: 12px;
            }
            font-weight: 700;
            @media only screen and (max-width: 1500px) {
                font-size: 13px;
                line-height: 13px;
                padding-top:13px;
            }
        }
    }
}

div.main_page {
    .select_multi_box {
        .multi_list{
            li{
                font-weight: 700;
                @media only screen and (max-width: 1500px) {
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }
}

.text-right{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

header.header{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding-top: 2.5vw;
    @media only screen and (max-width: 991px) {
        text-align: center;
        padding-top: 20px;
    }
    div.header_holder{
        position: relative;
        &:after{
            content: '';
            width: 100%;
            border-top: 1px solid rgba(110, 121, 142, 0.5);
            top: 60%;
            left: 0;
            position: absolute;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        div{
            &.logo_box, &.login_box{
                background: $theme_color1;
                display: inline-block;
                position: relative;
                z-index: 2;
            }
        }
    }
    .login_box{
        padding-left: 38px;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
    }
    div.logo_box{
        position: relative;
        @media only screen and (max-width: 991px) {
           padding-top: 10px;
        }
        a{
            background-color: $theme_color1;
            display: inline-block;
            position: relative;
            z-index: 2;
            padding-right: 38px;
            @media only screen and (max-width: 991px) {
                padding-right: 0;
                img{
                    max-width: 130px;
                    height: auto;
                }
            }
        }
    }
}

.slider{
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(110, 121, 142, 0.5);
    > ul{
        @include fill;
        > li{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size:cover;
            @media only screen and (max-width: 991px) {
                background-position: center bottom;
                background-size: 100% auto;
            }
            p{
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
            > .slider_image{
                -webkit-animation: kenburns-bottom 30s ease-out both;
	            animation: kenburns-bottom 30s ease-out both;
                @include fill;
                background-repeat: no-repeat;
                background-position: center;
                background-size:cover;
            }
            .inl{
                display: inline-block;
                margin-bottom: 0 !important;
                position: relative;
                //
                h1,p,a{
                    opacity: 1;
                }
                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: -5%;
                    right: 0;
                    height: 110%;
                    z-index: 2;
                  }
                  &:before {
                    background-color: $color-main;
                  }
                  &:after {
                    background-color: $black;
                    animation-delay: .5s;
                  }
            }
            &.active{
                .inl{
                    padding-right: 15px;
                    p{
                        color: $font_color;
                    }
                    h1,p,a{
                        position: relative;
                        animation: textHidden 0.1s 1.1s forwards;
                    }
                    &.inl1{
                        &:before {
                            animation: revealLTR 1.8s ease;
                          }
                          &:after {
                            animation: revealLTR 1s .6s ease;
                          }
                    }
                    &.inl2{
                        &:before {
                            animation: revealRTL 1.8s ease;
                          }
                          &:after {
                            animation: revealRTL 1s .6s ease;
                          }
                    }
                }
            }
            .slider_content{
                //text-align: center;
                padding-top: 30vh;
                color: $font_color;
                //font-size: 1.55vw;
                font-size: 1.2vw;
                line-height: 1.7vw;
                //line-height: 50px;
                font-weight: 200;
                @media only screen and (max-width: 991px) {
                    padding-top: 38vh;
                    font-size: 13px;
                    line-height: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                    text-align: center;
                }
                p{
                    font-weight: inherit;
                }
                > div{
                    //margin-bottom: 60px;
                    margin-bottom: 4%;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                    }
                    div{
                        h1{
                            font-size: 3.7vw;
                            line-height: 4.3vw;
                            @media only screen and (max-width: 991px) {
                                font-size: 32px;
                                line-height: 42px;
                            }
                        }
                        margin-bottom: 40px;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 20px;
                        }
                        color: $white;
                    }
                }
            }
        }
    }
    @keyframes dotprogress{
        0% {width: 0;}
        100% {width: 100%;}
      }
    .top_slider_dots{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 50px;
        //text-align: center;
        padding: 0 15px;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        ul{
           text-align: center;
            display: inline-block;
            position: relative;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 56px;
                position: relative;
                margin-left: -3px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    .pb{
                        display: none;
                    }
                }
                .pb{
                    width: calc(100% - 16px);
                    height: 2px;
                    background-color: rgba(110, 121, 142, 0.5);
                    position: absolute;
                    top: 46.9%;
                    left: 16px;
                    > div{
                        width: 0;
                        top: 0;
                        height: 100%;
                        left: 0;
                        background: $accent_color;
                    }
                }
                .dott{
                    width: 16px;
                    height: 0;
                    padding-bottom: 12px;
                    border: 2px solid #6e798e;
                    background: #0b1a2f;
                    border-radius: 50%;
                    @include anim;
                    background: #0b1526;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;
                }
                &.on{
                    > div.dott{
                        border:2px solid $accent_color;
                        @include glowing_shadow;
                    }
                    > .pb{
                        > div{
                            animation: dotprogress 5s linear;
                            transform-origin: center;
                        }
                    }
                }
                &.pre_on{
                    > .pb{
                        > div{
                            width: 100%;
                        }
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

section.how_it_works{
    &.reg{
        margin-top: 130px;
    }
    padding: 5% 0 5% 0;
    border-bottom: 1px solid rgba(110, 121, 142, 0.5);
    .section_header1{
        margin-bottom: 50px;
    }
    .work_list{
        > ul{
            > li{
                margin-bottom: 30px;
                > .row{
                    @media only screen and (max-width: 991px) {
                        display: flex;
                        flex-flow: column;
                        > div{
                            align-items: stretch;
                            &.col-md-4{
                                order: 1;
                            }
                            &.col-md-8{
                                order: 2;
                            }
                        }
                    }
                }
                &.left{
                    div.desc{
                        padding-left: 5%;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                        }
                    }
                }
                &.right{
                    div.desc{
                        padding-right: 5%;
                        @media only screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    &.image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 99%;
                        border: 2px solid $font_color;
                        border-radius: 50%;
                        background-position:center;
                        background-size:cover;
                        background-repeat:no-repeat;
                    }
                    &.desc{
                        padding-top: 10%; 
                        @media only screen and (max-width: 991px) {
                            padding-top: 30px;
                            text-align-last: center;
                        }
                    }
                }
            }
        }
    }
}

footer.footer{
    background: $theme_color3;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    color: $font_color;
    div.footer_top{
        padding: 5% 0 2.5% 0;
        border-bottom: 1px solid rgba(110, 121, 142, 0.5);
        .ft{
            > div{
                margin-bottom: 5%;
            }
        }
        .section_header1{
            margin-bottom: 50px;
        }
        .row{
            margin-left: -30px;
            margin-right: -30px;
            > div{
                &.col-md-6{
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
        @media only screen and (max-width: 1200px) {
            .first_box{
                margin-bottom: 20px;
            }
        }
        @media only screen and (min-width: 991px) {
            div.section_header1{
                margin-bottom: 5%;
                div.default_font{
                    h3{
                        font-size: 1.3vw;
                    }
                }
            }
        }
        
    }
    div.footer_bottom{
        padding: 30px 0;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        p{
            font-weight: inherit;
        }
        ul{
            text-align: right;
            @media only screen and (max-width: 991px) {
                padding-top: 15px;
            }
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    text-align: center;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    color: $font_color;
                    font-size: 13px;
                    line-height: 13px;
                    text-decoration: none;
                    @include anim;
                    font-weight: 600;
                    &:hover{
                        color: $accent_color;
                    }
                }
            }
        }
    }
}

.register_confirm {
    color:#fff;
    font-size:18px;
    line-height:26px;

    h4 {
        font-size:24px;
        line-height:30px;
        color:#d2d600;
        font-weight: 300;
        padding:20px 0;
    }
}

.page_404 {
    padding-top:250px;
    text-align: center;
    color:#fff;
    font-size:30px;

    h5 {
        font-size:200px;
        color:#d2d600;
    }
}
